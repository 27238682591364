import Login from '@/components/common/Login'
import { Dispatch, SetStateAction } from 'react'

interface LoginPageProps {
  setIsLoggedIn: Dispatch<SetStateAction<boolean>>
}

function LoginPage({ setIsLoggedIn }: LoginPageProps) {
  return (
    <div className="container mx-auto">
      <Login defaultTab="signin" setIsLoggedIn={setIsLoggedIn} />
    </div>
  )
}

export default LoginPage