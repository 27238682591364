import Hero from '../components/layout/Hero'
import Features from '../components/layout/Features'
// import Pricing from '../components/layout/Pricing'
import Contact from '../components/layout/Contact'

function Home() {
  return (
    <>
      <Hero />
      <Features />
      <Contact />
    </>
  )
}

export default Home