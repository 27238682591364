import { useState } from 'react'
import { Button } from "@/components/ui/button"
import { Input } from "@/components/ui/input"
import { submitEmail } from '../../../services/email.service'
import { useToast } from "@/hooks/use-toast"

export default function Contact() {
  const [firstName, setFirstName] = useState('')
  const [email, setEmail] = useState('')
  const [isSubmitting, setIsSubmitting] = useState(false)
  const { toast } = useToast()

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    setIsSubmitting(true)
    const result = await submitEmail(firstName, email)
    setIsSubmitting(false)
    
    if (result.success) {
      toast({
        title: "Success",
        description: result.message,
      })
      setFirstName('')
      setEmail('')
    } else {
      toast({
        title: "Error",
        description: result.message,
        variant: "destructive",
      })
    }
  }

  return (
    <section id="contact" className="w-full py-12 md:py-24 lg:py-32 bg-background">
      <div className="container px-4 md:px-6">
        <div className="flex flex-col items-center justify-center space-y-4 text-center">
          <h2 className="text-3xl font-bold tracking-tighter sm:text-4xl md:text-5xl">Be the First to Know</h2>
          <p className="max-w-[600px] text-xl text-muted-foreground">
            Join our waitlist to get early access and exclusive offers when we launch.
          </p>
          <div className="w-full max-w-sm space-y-2">
            <form onSubmit={handleSubmit} className="flex flex-col gap-4">
              <Input 
                type="text" 
                placeholder="Enter your first name" 
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                className="flex-grow rounded-full border-2 border-gray-300 focus:border-primary"
                required
              />
              <Input 
                type="email" 
                placeholder="Enter your email" 
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="flex-grow rounded-full border-2 border-gray-300 focus:border-primary"
                required
              />
              <Button 
                type="submit" 
                className="w-full rounded-full bg-gray-900 text-white hover:bg-gray-800"
                disabled={isSubmitting}
              >
                {isSubmitting ? 'Submitting...' : 'Join Waitlist'}
              </Button>
            </form>
          </div>
        </div>
      </div>
    </section>
  )
}