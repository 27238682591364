'use client'

import { useState } from 'react'
import { Button } from "@/components/ui/button"
import { Input } from "@/components/ui/input"
import { ArrowRight } from "lucide-react"
import { submitEmail } from '../../../services/email.service'
import { useToast } from "@/hooks/use-toast"

export default function Hero() {
  const [email, setEmail] = useState('')
  const [firstName, setFirstName] = useState('')
  const [isSubmitting, setIsSubmitting] = useState(false)
  const { toast } = useToast()

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    setIsSubmitting(true)
    const result = await submitEmail(firstName, email)
    setIsSubmitting(false)
    
    if (result.success) {
      toast({
        title: "Success",
        description: "You've successfully joined our beta program!",
      })
      setEmail('')
      setFirstName('')
    } else {
      toast({
        title: "Error",
        description: result.message,
        variant: "destructive",
      })
    }
  }

  return (
    <section className="w-full py-8 md:py-24 lg:py-32 xl:py-48 bg-background">
      <div className="container px-4 md:px-6">
        <div className="flex flex-col items-center space-y-10 text-center">
          <div className="space-y-6 max-w-4xl">
            <h1 className="text-5xl font-extrabold tracking-tighter sm:text-5xl md:text-6xl lg:text-7xl/none bg-clip-text text-transparent bg-gradient-to-r from-blue-800 to-blue-600">
              Tired of Manually Splitting PDFs? Try AutoSplit
            </h1>
            <p className="mx-auto max-w-[800px] text-xl md:text-2xl text-gray-600 dark:text-gray-300 leading-relaxed">
              Eliminate manual PDF splitting with AutoSplit’s AI-driven solution.
              Quickly and effortlessly divide multi-document PDFs into individual files.
            </p>
          </div>
          <div className="w-full max-w-md bg-white dark:bg-gray-800 p-8 rounded-lg shadow-lg border border-gray-200">
            <h2 className="text-3xl font-bold text-blue-600 dark:text-blue-400 mb-4">⚡ Join the Waitlist</h2>
            <p className="text-gray-700 dark:text-gray-300 mb-6 text-lg">
              Join our waitlist to be among the first to experience AutoSplit. 
              Get notified about early access and exclusive updates!
            </p>
            <form onSubmit={handleSubmit} className="flex flex-col space-y-4">
              <Input 
                type="text" 
                placeholder="Enter your first name" 
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                className="flex-grow bg-gray-50 dark:bg-gray-700 text-gray-900 dark:text-gray-100 text-lg"
                required
              />
              <Input 
                type="email" 
                placeholder="Enter your email" 
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="flex-grow bg-gray-50 dark:bg-gray-700 text-gray-900 dark:text-gray-100 text-lg"
                required
              />
              <Button 
                type="submit" 
                className="bg-gradient-to-r from-blue-600 to-blue-700 hover:from-blue-700 hover:to-blue-800 text-white text-lg py-6" 
                disabled={isSubmitting}
              >
                {isSubmitting ? 'Joining...' : 'Join Beta Program'}
                <ArrowRight className="ml-2 h-5 w-5" />
              </Button>
            </form>
            <p className="text-gray-600 dark:text-gray-400 text-base mt-4">Limited spots available. Don't miss out!</p>
          </div>
        </div>
      </div>
    </section>
  )
}