'use client'

import { useState } from 'react'
import { Button } from "@/components/ui/button"
import ThemeToggle from '@/components/common/ThemeToggle'
import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar"
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from "@/components/ui/dropdown-menu"
import { Link, useLocation } from 'react-router-dom'
import Logo from '@/components/common/Logo'

interface HeaderProps {
  isLoggedIn: boolean;
  userEmail?: string;
  onLogout: () => void;
}

export default function Header({ isLoggedIn, userEmail, onLogout }: HeaderProps) {
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const location = useLocation()
  const isHomePage = location.pathname === '/'

  const scrollToSection = (sectionId: string) => {
    const element = document.getElementById(sectionId);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <header className="border-b">
      <div className="container mx-auto px-4 h-20 flex items-center justify-between">
        <Link to="/" className="flex items-center justify-center">
          {/* <FileText className="h-8 w-8 text-primary" /> */}
          <Logo />
          <span className="sr-only">AutoSplit home page</span>
        </Link>
        <nav className="flex items-center gap-6 sm:gap-8">
          {isHomePage && (
            <>
              <button onClick={() => scrollToSection('features')} className="text-base font-medium hover:text-primary transition-colors">
                Features
              </button>
              <button onClick={() => scrollToSection('contact')} className="text-base font-medium hover:text-primary transition-colors">
                Contact
              </button>
            </>
          )}
          <ThemeToggle />
          {isLoggedIn ? (
            <DropdownMenu open={isMenuOpen} onOpenChange={setIsMenuOpen}>
              <DropdownMenuTrigger asChild>
                <Button variant="ghost" className="relative h-10 w-10 rounded-full">
                  <Avatar className="h-10 w-10">
                    <AvatarImage src="/placeholder-avatar.jpg" alt="User avatar" />
                    <AvatarFallback>{userEmail?.charAt(0).toUpperCase()}</AvatarFallback>
                  </Avatar>
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent className="w-56" align="end" forceMount>
                <DropdownMenuItem className="flex-col items-start">
                  <div className="text-sm font-medium">Signed in as</div>
                  <div className="text-base font-medium">{userEmail}</div>
                </DropdownMenuItem>
                <DropdownMenuItem>Profile</DropdownMenuItem>
                <DropdownMenuItem>Settings</DropdownMenuItem>
                <DropdownMenuItem onClick={onLogout}>Log out</DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>
          ) : (
            <>
            </>
          )}
        </nav>
      </div>
    </header>
  )
}