import { FileText, Merge, Shield, Tags } from "lucide-react"

export default function Features() {
  return (
    <section id="features" className="w-full py-12 md:py-24 lg:py-32 bg-gray-100 dark:bg-gray-800">
      <div className="container px-4 md:px-6">
        <h2 className="text-3xl font-bold tracking-tighter sm:text-5xl text-center mb-12">Key Features</h2>
        <div className="grid gap-10 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-4 items-start justify-center">
        <div className="flex flex-col items-center space-y-2 border-gray-800 p-4 rounded-lg">
            <FileText className="h-12 w-12 mb-2 text-blue-600" /> 
            <h3 className="text-2xl font-bold">Intelligent Splitting</h3> 
            <p className="text-base text-gray-500 dark:text-gray-400 text-center">
              Automatically separate multi-document PDFs into individual files with AI-powered accuracy.
            </p>
          </div>
          <div className="flex flex-col items-center space-y-2 border-gray-800 p-4 rounded-lg">
            <Merge className="h-12 w-12 mb-2 text-blue-600" /> 
            <h3 className="text-2xl font-bold">Smart Merging</h3> 
            <p className="text-base text-gray-500 dark:text-gray-400 text-center"> 
              Easily merge over-split documents with a simple click-and-combine interface.
            </p>
          </div>
          <div className="flex flex-col items-center space-y-2 border-gray-800 p-4 rounded-lg">
            <Tags className="h-12 w-12 mb-2 text-blue-600" /> 
            <h3 className="text-2xl font-bold">Document Categories</h3>
            <p className="text-base text-gray-500 dark:text-gray-400 text-center"> 
              Add comma-separated categories to guide AI in splitting PDFs more accurately based on content.
            </p>
          </div>
          <div className="flex flex-col items-center space-y-2 border-gray-800 p-4 rounded-lg">
            <Shield className="h-12 w-12 mb-2 text-blue-600" />
            <h3 className="text-2xl font-bold">Privacy First</h3> 
            <p className="text-base text-gray-500 dark:text-gray-400 text-center"> 
              We delete all PDFs after you're happy with the results, ensuring privacy and security.
            </p>
          </div>
        </div>
      </div>
    </section>
  )
}