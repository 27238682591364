'use client'

import { useState } from 'react'
import { Button } from "@/components/ui/button"
import { Card, CardContent } from "@/components/ui/card"
import { ChevronLeft, ChevronRight, Download, X } from 'lucide-react'
import { Document } from '@/types/document'

interface DocumentCarouselProps {
  documents: Document[]
  onMerge: (selectedDocuments: number[]) => void
}

export default function DocumentCarousel({ documents, onMerge }: DocumentCarouselProps) {
  const [selectedDocuments, setSelectedDocuments] = useState<number[]>([])
  const [currentDocument, setCurrentDocument] = useState<Document | null>(null)

  const toggleDocumentSelection = (id: number) => {
    setSelectedDocuments(prev => 
      prev.includes(id) ? prev.filter(docId => docId !== id) : [...prev, id]
    )
  }

  const handleMerge = () => {
    onMerge(selectedDocuments)
    setSelectedDocuments([])
  }

  const handleDownloadAll = () => {
    console.log('Downloading all documents')
    // Implement download all logic here
  }

  return (
    <div className="space-y-4">
      {currentDocument ? (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white p-4 rounded-lg max-w-3xl w-full max-h-[90vh] overflow-y-auto">
            <div className="flex justify-between items-center mb-4">
              <h2 className="text-2xl font-bold">{currentDocument.name}</h2>
              <Button variant="ghost" onClick={() => setCurrentDocument(null)}>
                <X className="h-6 w-6" />
              </Button>
            </div>
            <iframe src={currentDocument.pdfUrl} className="w-full h-[70vh]" />
          </div>
        </div>
      ) : (
        <>
          <div className="flex justify-between items-center">
            <h2 className="text-2xl font-bold">Split Documents</h2>
            <div className="space-x-2">
              {selectedDocuments.length >= 2 && (
                <Button onClick={handleMerge}>
                  Merge Selected
                </Button>
              )}
              <Button onClick={handleDownloadAll}>
                Download All
              </Button>
            </div>
          </div>
          <div className="relative">
            <div className="flex space-x-4 overflow-x-auto py-4">
              {documents.map((doc) => (
                <Card 
                  key={doc.id} 
                  className={`flex-shrink-0 w-64 cursor-pointer ${
                    selectedDocuments.includes(doc.id) ? 'ring-2 ring-primary' : ''
                  }`}
                  onClick={() => toggleDocumentSelection(doc.id)}
                >
                  <CardContent className="p-4">
                    <img 
                      src={doc.thumbnail} 
                      alt={doc.name} 
                      className="w-full h-40 object-cover mb-2"
                      onClick={(e) => {
                        e.stopPropagation()
                        setCurrentDocument(doc)
                      }}
                    />
                    <h3 className="font-semibold mb-2">{doc.name}</h3>
                    <Button 
                      variant="outline" 
                      className="w-full"
                      onClick={(e) => {
                        e.stopPropagation()
                        console.log('Downloading:', doc.name)
                        // Implement single document download logic here
                      }}
                    >
                      <Download className="mr-2 h-4 w-4" /> Download
                    </Button>
                  </CardContent>
                </Card>
              ))}
            </div>
            <Button 
              variant="ghost" 
              className="absolute left-0 top-1/2 transform -translate-y-1/2"
              onClick={() => {
                const container = document.querySelector('.overflow-x-auto')
                if (container) container.scrollBy({ left: -300, behavior: 'smooth' })
              }}
            >
              <ChevronLeft className="h-6 w-6" />
            </Button>
            <Button 
              variant="ghost" 
              className="absolute right-0 top-1/2 transform -translate-y-1/2"
              onClick={() => {
                const container = document.querySelector('.overflow-x-auto')
                if (container) container.scrollBy({ left: 300, behavior: 'smooth' })
              }}
            >
              <ChevronRight className="h-6 w-6" />
            </Button>
          </div>
        </>
      )}
    </div>
  )
}