import Login from '@/components/common/Login'
import { Dispatch, SetStateAction } from 'react'

interface SignupPageProps {
  setIsLoggedIn: Dispatch<SetStateAction<boolean>>
}

export default function SignupPage({ setIsLoggedIn }: SignupPageProps) {
  return (
    <div className="container mx-auto">
       <Login defaultTab="signup" setIsLoggedIn={setIsLoggedIn} />
    </div>
  )
}