'use client'

import { useState } from 'react'
import { Button } from "@/components/ui/button"
import { Input } from "@/components/ui/input"
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card"
import { Upload } from 'lucide-react'
import { Progress } from "@/components/ui/progress"
import { Document } from '@/types/document'

interface PDFUploaderProps {
  onSplitComplete: (documents: Document[]) => void
}

export default function PDFUploader({ onSplitComplete }: PDFUploaderProps) {
  const [file, setFile] = useState<File | null>(null)
  const [categories, setCategories] = useState('')
  const [isUploading, setIsUploading] = useState(false)
  const [uploadProgress, setUploadProgress] = useState(0)

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files
    if (files && files.length > 0) {
      setFile(files[0])
    }
  }

  const handleUpload = async () => {
    if (!file) return

    setIsUploading(true)
    setUploadProgress(0)

    // Simulating file upload and processing
    for (let i = 0; i <= 100; i += 10) {
      setUploadProgress(i)
      await new Promise(resolve => setTimeout(resolve, 500))
    }

    // Simulating split documents
    const mockDocuments: Document[] = [
      { id: 1, name: 'Document 1', thumbnail: '/placeholder.svg?height=300&width=200', pdfUrl: '#' },
      { id: 2, name: 'Document 2', thumbnail: '/placeholder.svg?height=300&width=200', pdfUrl: '#' },
      { id: 3, name: 'Document 3', thumbnail: '/placeholder.svg?height=300&width=200', pdfUrl: '#' },
    ]

    onSplitComplete(mockDocuments)
    setIsUploading(false)
    setFile(null)
  }

  return (
    <Card className="max-w-2xl mx-auto">
      <CardHeader>
        <CardTitle>Upload and Split PDF</CardTitle>
      </CardHeader>
      <CardContent className="space-y-4">
        <div className="flex items-center space-x-2">
          <Input 
            type="file" 
            accept=".pdf" 
            onChange={handleFileChange} 
            className="file:mr-4 file:py-2 file:px-4 file:rounded-full file:border-0 file:text-sm file:font-semibold file:bg-primary file:text-primary-foreground hover:file:bg-primary/90"
          />
          <Button onClick={handleUpload} disabled={!file || isUploading}>
            <Upload className="mr-2 h-4 w-4" /> Upload
          </Button>
        </div>
        
        <div>
          <label htmlFor="categories" className="block text-sm font-medium text-gray-700 mb-1">
            Document Categories (comma-separated)
          </label>
          <Input
            id="categories"
            placeholder="e.g. rental, medical, bank statements"
            value={categories}
            onChange={(e) => setCategories(e.target.value)}
          />
        </div>

        {isUploading && (
          <div className="space-y-2">
            <Progress value={uploadProgress} className="w-full" />
            <p className="text-sm text-center">Uploading and processing your document...</p>
          </div>
        )}
      </CardContent>
    </Card>
  )
}