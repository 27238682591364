const BACKEND_BASE_URL = import.meta.env.VITE_BACKEND_BASE_URL || ''

export async function submitEmail(firstName: string, email: string) {
  try {
    const response = await fetch(`${BACKEND_BASE_URL}/api/v1/waitlist-entries`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ first_name: firstName, email })
    })

    if (!response.ok) {
      console.error('Response error:', response.status, response.statusText, 'URL:', BACKEND_BASE_URL)
      throw new Error('Failed to submit email')
    }

    const data = await response.json()
    return { success: true, message: data.message }
  } catch (error) {
    if (error instanceof Error) {
      console.error('Error submitting email:', error.message, error.stack, 'URL:', BACKEND_BASE_URL)
    } else {
      console.error('Unknown error submitting email:', error, 'URL:', BACKEND_BASE_URL)
    }
    return { success: false, message: 'Failed to submit email' }
  }
}