import autosplitLogo from "@/assets/autosplitlogo.svg";

export default function Logo() {
  return (
    <div className="flex items-center space-x-2">
      <img src={autosplitLogo} alt="AutoSplit logo" className="w-10 h-10" />
      <span className="text-2xl font-bold bg-gradient-to-r from-blue-600 to-blue-400 bg-clip-text text-transparent">
        Auto<span className="text-blue-600">Split</span>
      </span>
    </div>
  );
}