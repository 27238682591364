import { ReactNode, useState, useEffect } from 'react'
import Header from './Header'
import Footer from './Footer'
import { Toaster } from "@/components/ui/toaster"
import { useTheme } from '@/components/common/ThemeContext'


interface LayoutProps {
  children: ReactNode;
  isLoggedIn: boolean;
}

export default function Layout({ children, isLoggedIn }: LayoutProps) {
  const [userEmail, setUserEmail] = useState('')
  const { theme } = useTheme()

  useEffect(() => {
    if (isLoggedIn) {
      // In a real app, you'd get this from the token or API
      setUserEmail('user@example.com')
    } else {
      setUserEmail('')
    }
  }, [isLoggedIn])

  const handleLogout = () => {
    localStorage.removeItem('authToken')
    window.location.href = '/'
  }

  return (
    <div className={`flex flex-col min-h-screen bg-background text-foreground ${theme}`}>
      <Header isLoggedIn={isLoggedIn} userEmail={userEmail} onLogout={handleLogout} />
      <main className="flex-grow container mx-auto py-8">
        {children}
      </main>
      <Footer />
      <Toaster />
    </div>
  )
}