import React from 'react'

interface DashboardLayoutProps {
  children: React.ReactNode
}

export default function DashboardLayout({ children }: DashboardLayoutProps) {
  return (
    <div className="container mx-auto p-4 min-h-screen flex flex-col justify-center">
      <h1 className="text-3xl font-bold mb-6 text-center">AutoSplit Dashboard</h1>
      {children}
      <p className="text-center text-sm text-gray-500 mt-4">
        Note: Documents are deleted after processing or when you close this page.
      </p>
    </div>
  )
}