'use client'

import { useState } from 'react'
import PDFUploader from '@/components/features/PDFUploader'
import DocumentCarousel from '@/components/features/DocumentCarousel'
import DashboardLayout from '../components/layout/DashboardLayout'
import { Document } from '@/types/document'

export default function UserDashboard() {
  const [splitDocuments, setSplitDocuments] = useState<Document[]>([])

  const handleSplitComplete = (documents: Document[]) => {
    setSplitDocuments(documents)
  }

  const handleMerge = (selectedDocuments: number[]) => {
    // Simulating merge process
    const mergedDocument: Document = {
      id: Date.now(),
      name: `Merged Document ${splitDocuments.length + 1}`,
      thumbnail: '/placeholder.svg?height=300&width=200',
      pdfUrl: '#'
    }

    const updatedDocuments = splitDocuments.filter(doc => !selectedDocuments.includes(doc.id))
    updatedDocuments.push(mergedDocument)
    setSplitDocuments(updatedDocuments)
  }

  return (
    <DashboardLayout>
      {splitDocuments.length === 0 ? (
        <PDFUploader onSplitComplete={handleSplitComplete} />
      ) : (
        <DocumentCarousel documents={splitDocuments} onMerge={handleMerge} />
      )}
    </DashboardLayout>
  )
}