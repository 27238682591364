import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import { useState, useEffect } from 'react'
import Layout from './components/layout/Layout'
import Home from './pages/Home'
import UserDashboard from './pages/UserDashboard'
import ProtectedRoute from './components/common/ProtectedRoute'
import { ThemeProvider } from './components/common/ThemeContext'
import Login from './pages/LoginPage'
import Signup from './pages/SignupPage'
import { Analytics } from "@vercel/analytics/react";

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false)

  useEffect(() => {
    const token = localStorage.getItem('authToken')
    setIsLoggedIn(!!token)
  }, [])

  return (
    <ThemeProvider>
      <Router>
        <Layout isLoggedIn={isLoggedIn}>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/login" element={<Login setIsLoggedIn={setIsLoggedIn} />} />
            <Route path="/signup" element={<Signup setIsLoggedIn={setIsLoggedIn} />} />
            <Route 
              path="/dashboard"
              element={
                <ProtectedRoute>
                  <UserDashboard />
                </ProtectedRoute>
              }
            />
          </Routes>
        </Layout>
        <Analytics />
      </Router>
    </ThemeProvider>
  )
}

export default App